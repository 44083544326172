@import "../../styles/variable";

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, .5);
}

.agreement {
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 11;
  margin-left: -445px;
  width: 890px;
  padding-top: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.header {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;

  > span {
    position: relative;
    font-size: 30px;
    font-weight: 500;

    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 10px;
      right: 10px;
      height: 8px;
      background-color: $primary;
    }
  }
}

.content {
  padding: 0 35px;
  height: calc(100vh - 300px);
  overflow: auto;
}

.footer {
  height: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  .btn {
    padding: 0 65px;
  }
}

.tips {
  display: flex;
  flex-direction: column;
}

.tip {
  font-size: 16px;
  color: $text-second;
}

.counter {
  > em {
    position: relative;
    padding: 0 10px;
    font-style: normal;
    font-size: 0;

    &::before {
      position: absolute;
      content: "";
      bottom: -4px;
      left: 0;
      right: 0;
      height: 8px;
      background: $primary-gradient;
    }

    &::after {
      position: relative;
      font-size: 16px;
      content: attr(data-counter);
    }
  }
}
