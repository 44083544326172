@import '../../styles/variable';

.btn {
  > a {
    text-decoration: none;
    color: inherit;
  }

  &.btn-theme-default {
    background-color: rgba(0, 0, 0, .06);
    color: #000;

    .loading-icon {
      color: #000;
    }
  }

  &.btn-theme-primary {
    border-color: $primary;
    background-color: $primary;
    color: #000;

    .loading-icon {
      color: #000;
    }
  }

  &.btn-theme-ghost {
    border-color: $primary;
    background-color: transparent;
    color: $primary;

    .loading-icon {
      color: $primary;
    }
  }

  &.btn-theme-danger {
    border-color: $danger;
    background-color: $danger;
    color: #fff;

    .loading-icon {
      color: #fff;
    }
  }

  &.disabled,
  &[disabled],
  &.loading:not(.btn-theme-ghost) {
    position: relative;
    border-color: rgba(255, 255, 255, .5);

    &::before {
      content: "";
      position: absolute;
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      pointer-events: none;
    }
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &.loading {
    cursor: progress;
  }

  .loading-icon {}
}
