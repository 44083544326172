@import '../../styles/variable';

.input {
  font-size: inherit;
  font-family: inherit;
}

.input-size-small {
  height: $height-small;
}

.input-size-medium {
  height: $height;
}

.input-size-large {
  height: $height-large;
}

.textarea {
  font-size: inherit;
  font-family: inherit;
}
