// @import "../../../styles/variable";

.spin-loading {
  position: relative;

  .mask {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.loading-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50px;
}
