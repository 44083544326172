@import '../../styles/variable';

.modal {
  :global {
    .modal-content {
      position: relative;
    }
  }
}

.modal-header {
  padding: 16px 24px;
  color: rgba(0,0,0,.85);
  background-color: #fff;
  border-bottom: 1px solid $border;
  border-radius: 2px 2px 0 0;
  font-size: 16px;
  font-weight: 500;
}

.modal-body {
  padding: 16px 24px;
}

.modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid $border;
  border-radius: 0 0 2px 2px;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  top: 0;
  width: 55px;
  height: 55px;
  cursor: pointer;

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 50%;

    .close-icon {
      color: #979797;
      font-size: 10px;
    }
  }
}

.opt-btn {
  padding: 0 22px;
  margin-left: 12px;
  height: 34px;
}