@import '../../styles/variable';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.total {
  margin-right: 12px;
}

.item-jump,
.item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.item {
  border: 1px solid $border;
  border-radius: 3px;
  margin-right: 10px;
  font-size: 18px;
  padding: 0 8px;

  .arrow-icon {
    color: $border;
  }

  &:hover {
    border-color: $primary;
    color: $primary;

    .arrow-icon {
      color: $primary;
    }
  }

  &.active {
    background-color: $primary;
    border-color: $primary;
    color: #000;
  }

  &:last-child {
    margin-right: 0;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
  }
}

.item-jump {
  font-weight: 500;
  position: relative;

  &:hover {
    .item-more {
      display: none;
    }
    .item-jump-icon {
      display: block;
    }
  }

  .item-jump-icon {
    color: $primary;
    display: none;
  }

  .item-more {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $border;
  }
}