@import '../../../styles/variable';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  background: $primary-gradient;
  padding: 0 50px 40px;
  min-width: 1280px;
}

.info {
  display: inline-flex;
  align-items: center;
  height: 30px;
  background-color: #fff;
  margin-left: 14px;
  border-radius: 15px;
  border: 1px solid #000;
  padding-right: 14px;
  cursor: pointer;

  .admin {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0065F8;
    color: #fff;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    transform: translateX(-1px);
    font-size: 18px;
  }

  .user-name {
    vertical-align: middle;
    font-size: 18px;
    margin-left: 14px;
    white-space: nowrap;

    &.has-admin {
      margin-left: 6px;
    }
  }

  .arrow-icon {
    font-size: 12px;
    margin-left: 8px;
  }
}
