@import "../../../styles/variable";

.menu {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  box-shadow: 0 0 19px rgba(200, 200, 200, .26);
}

.link {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 58px;
  text-decoration: none;
  color: $text-strong;
  font-size: 12px;

  :global(.icon) {
    color: #424242;
    font-size: 26px;
    margin-bottom: 2px;
  }
}