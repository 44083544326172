.overlay {
  position: absolute;
  z-index: 9;

  .unbeautiful {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
  }

  &.bottomLeft,
  &.bottomCenter,
  &.bottomRight {
    .unbeautiful {
      bottom: 100%;
    }
  }

  &.topLeft,
  &.topCenter,
  &.topRight {
    .unbeautiful {
      top: 100%;
    }
  }

  &.topLeft,
  &.topRight {
    transform: translateY(-100%);
  }

  &.bottomCenter {
    transform: translateX(-50%);
  }

  &.topCenter {
    transform: translateX(-50%) translateY(-100%);
  }

  &.bottomLeft,
  &.bottomCenter,
  &.bottomRight {
    &.move-appear {
      animation: bottomAppear .2s ease-out;
    }

    &.move-leave {
      animation: bottomLeave .2s ease-out;
    }
  }

  &.topLeft,
  &.topCenter,
  &.topRight {
    &.move-appear {
      animation: topAppear .2s ease-out;
    }

    &.move-leave {
      animation: topLeave .2s ease-out;
    }
  }
}

@keyframes bottomAppear {
  0% {
    margin-top: -5px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes bottomLeave {
  0% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: -5px;
    opacity: 0;
  }
}

@keyframes topAppear {
  0% {
    margin-top: -5px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes topLeave {
  0% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: -5px;
    opacity: 0;
  }
}