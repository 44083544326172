@import "../../styles/variable";

.radio-group {
  & > .radio-wrapper {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.radio-wrapper {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;

  &.radio-wrapper-disabled {
    cursor: not-allowed;

    .radio,
    .radio-input {
      cursor: not-allowed;
    }

    .radio-input {
      & + .radio-inner {
        border-color: $border;
        background-color: #f5f5f5;
        &::before {
          background-color: $border;
        }
      }
    }
  }
}

.radio {
  position: relative;
  cursor: pointer;
}

.radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;

  &:checked {
    & + .radio-inner {
      border-color: $primary;

      &::before {
        display: block;
      }
    }
  }
}

.radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border: 1px solid $border;
  border-radius: 50%;

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    display: none;
    content: "";
    width: 16px;
    height: 16px;
    background-color: $primary;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.radio-content {
  margin-left: 8px;
}