@import '../../styles/variable';

.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.table-container {
  flex: 1;

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    &.narrow {
      tr {
        th,
        td {
          &:first-child {
            padding-left: 18px;
          }
        }
      }
    }

    tr {
      th,
      td {
        padding: 5px 8px;
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
        }
      }
    }
  }

  thead {
    tr {
      height: 60px;
      // background-color: #fafafa;
      white-space: nowrap;
      font-size: 16px;
      border-bottom: 1px solid $border;

      > th {
        font-weight: normal;
      }

      .title {
        padding: 0 9px;
      }
    }
  }

  tbody {
    tr {
      height: 60px;
      @extend .word-break;
      color: rgba($text-strong, .7);
      
      &:nth-child(2n) {
        background-color: rgba($primary, .2);
      }

      // &:hover {
      //   background-color: rgba($primary, .2);
      // }
    }
  }
}