*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

a {
  text-decoration: none;
}

.plus-icon {
  display: inline-block;
  position: relative;
  width: 6px;
  height: 1px;

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: currentColor;
  }

  &::after {
    transform: rotate(90deg);
  }
}

.text-btn {
  white-space: nowrap;
  color: #F08500;
  font-size: 14px;
  cursor: pointer;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1BkxddiNRP6p7Ge1lOVIQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1BkxddiNRNqp7Ge1lOVIQ.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1BkxddiNRPqp7Ge1lOVIQ.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1BkxddiNRMap7Ge1lOVIQ.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1BkxddiNRPap7Ge1lOVIQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1BkxddiNRPKp7Ge1lOVIQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1BkxddiNRMqp7Ge1lOQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1DspC4i5hMKB8O-9VOA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1DspC4idhMKB8O-9VOA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1DspC4i9hMKB8O-9VOA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1DspC4iBhMKB8O-9VOA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1DspC4ixhMKB8O-9VOA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1DspC4i1hMKB8O-9VOA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.linghemedia.com.cn/qgwtest/alegreyasanssc/Hf1DspC4iNhMKB8O-8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

