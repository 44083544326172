@import '../../styles/variable';

.select-size-small {
  height: $height-small;
}

.select-size-medium {
  height: $height;
  font-size: 14px;
}

.select-size-large {
  height: $height-large;
}
