@import '../../styles/variable';

.theme-basic {
  background-color: #E1F4FF;
  background-image: linear-gradient(90deg, rgba(80, 103, 255, 0.08) 3%, rgba(0, 0, 0, 0) 3%), linear-gradient(360deg, rgba(80, 103, 255, 0.08) 3%, rgba(0, 0, 0, 0) 3%);
  background-size: 40px 40px;
}

.theme-topic {
  background-color: #082AD3;
  background-image: url('../../assets/topic/topic-bg.png');
  background-size: 30%;
  background-position: 50% 600%;
}

.theme-playlet {
  background-image: url('../../assets/banner/playlet-moon.png'), url('../../assets/banner/playlet-bg.jpg');
  background-size: 500px, 100% auto;
  background-position: calc(100vw - 370px) -350px, center center;
  background-repeat: no-repeat;
}

.upload-item {
  &::before {
    top: calc(100% - 8px)!important;
  }
}

.upload {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 32px;
  background-color: $score-shadow;
  border: 1px solid #000;
  border-radius: 20px;
  transform: translateY(4px);

  &.active {
    &::after {
      display: none;
    }
  }

  > img {
    position: relative;
    height: 20px;
    z-index: 1;
    transform: translate(-4px, -3px);
  }

  &::before {
    position: absolute;
    z-index: 0;
    content: "";
    width: 108px;
    height: 32px;
    border: 1px solid #000;
    background-color: $primary;
    border-radius: 20px;
    transform: translate(-4px, -2px);
  }

  &::after {
    position: absolute;
    top: -26px;
    left: 50%;
    transform: translateX(calc(-50% - 4px));
    content: "";
    z-index: 2;
    width: 41px;
    height: 26px;
    background: url('../../assets/rabbit.png') 50% / cover no-repeat;
  }
}

.upload-active {
  // 0.32
  display: inline-flex;
  width: 108px;
  height: 34px;
  transform: translateY(3px);

  > img {
    width: 100%;
  }
}

.menuItem {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 8px;

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 34px;
      height: 20px;
      margin-top: -10px;
      background: $primary-gradient;
      border-radius: 0 10px 10px 0;
    }
  }
}

.icon {
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;

  > img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
}
