@import '../../styles/variable';

.checkbox-wrapper {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;

    .checkbox,
    .checkbox-input {
      cursor: not-allowed;
    }

    .checkbox-inner {
      border-color: rgba(0, 0, 0, .3);

      &::before {
        background-color: rgba(0, 0, 0, .3);
      }
    }
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
}

.checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:checked {
    & + .checkbox-inner {
      background-color: $primary;
      border-color: $primary;
      &::before {
        display: block;
      }
    }
  }
}

.checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 2px;

  &::before {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 10px;
    height: 7px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMYAAACMCAMAAAAQhiZlAAAAV1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////+ORg7oAAAAHHRSTlMAKtSv73AwogNlOezi26trUDQJjINbQDIa+cx8Eg/HjQAAAklJREFUeNrt3VluAkEMRdFHqidmyEiS2v86E4cPBBRdBhLFz/JdgO0jAeKrG5wNs7ZffW66RxDXpSbvW0xBW9vkQwmcTVI+6n0GwiYP+TTwVVDkJ7AlivM24KqsyEuu311RFHsDUaIo9wKeLivyM2gaUeQVWBpT5B4kjSpyC47GFZnk/0hF0QxgqKig+5dbUzQdCPpR0H/BvxUOPlJVxcME9guFnUJhp1DYKRR2CoWdQmGnUNgpFHYKhZ1CYadQ2CkUdgqFnUJhp1DYKRR2CoWdQmGnP1A8vr08r/rpdsDFCBSbZd43Tx3KESjW+VDTohSB4iMflQoDCBTL+ggCxVN9CIFiVh9DoECqDmJQDPPaKAYFtrVhFApMK+M4FOjHB5Io0I+OZFFgOj6URIFtZSyHAsO8MphCAaTaaAoFuqY2nEEBtNXxDAogVRcwKJysUC0xvkC9xvD4qxYZHX71KoOjb1pmbPDN6wyNvWuhkaF3rzQw8leWEihUawkUqsUECtVqAoVqOYFCtZ5AoTqAQKE6gUChOoJAoTqDQKE6hEChOoVAoTqGQKE6h0ChOohAoTqJQKE6ikChOotAoTqMQKE6jUChOo5AoTqPQKE6kEChOpFAoXEwKBQOCoU4PCjE4UEhDg8KcXhQiMODQhweFOLwoBCHB4U4PCjE4UEhDg8KcXhQiMODQhweFOLwoBCHB4U4PCjE4UEhDg8KcXhQiMODQhweFOLwoDh/3U2iVJy8fKiheBJ4uS4t9ohFongq++V2r+v16w7/2ReJbH0EH/n1pgAAAABJRU5ErkJggg==') 50% / cover no-repeat;
  }
}

.checkbox-content {
  margin-left: 8px;
}