@import '../../../styles/variable';

.modal-confirm {
  padding: 32px 32px 24px;
}

.modal-confirm-body {
  .modal-confirm-body-header {
    display: flex;
  }

  .exclamation-icon {
    color: $primary;
    font-size: 22px;
  }

  .modal-confirm-body-title {
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }

  .modal-confirm-body-content {
    margin: 8px 0 0 32px;
  }
}

.modal-confirm-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn {
  padding: 0 25px;

  & + .btn {
    margin-left: 10px;
  }
}