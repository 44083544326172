@import '../../styles/variable';

.menu-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 32px;
  padding: 0 12px;
  line-height: 22px;
  cursor: pointer;
  
  > a {
    color: $text-strong;
    text-decoration: none;
  }

  &.active {
    background-color: rgba($primary, .6);
  
    &:hover {
      background-color: rgba($primary, .6);
    }
  }

  &:hover {
    // background-color: rgba($primary, .6);
    background-color: #f5f5f5;
  }
}