@import "../../styles/variable";

.mobile-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  border: 1px solid currentColor;
  border-radius: 3px;

  &.disabled {
    color: rgba(0, 0, 0, .2);
  }
}

.nav {
  display: flex;
  align-items: center;
  color: $text-second;

  > em {
    color: $text-strong;
    font-style: normal;
    font-weight: 500;
    margin-right: 2px;
  }
}

