@import '../../styles/variable';

.select {
  position: relative;
}

.select-size-small {
  height: $height-small;
}

.select-size-medium {
  height: $height;
}

.select-size-large {
  height: $height-large;
}

.select-selector {
  &.allow-clear {
    &:hover {
      .opt-icon {
        &.arrow-icon {
          display: none;
        }

        &.close-icon {
          display: inline-flex;
        }
      }
    }
  }

  .opts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .opt-icon {
    align-items: center;
    justify-content: center;

    &.arrow-icon {
      display: inline-flex;
    }

    &.close-icon {
      display: none;
      color: $text-second;
    }
  }

  // .placeholder {
  //   color: $text-second;
  // }
}

.select-selection-item {
  &.active {
    background-color: rgba($primary, .6);
  
    &:hover {
      background-color: rgba($primary, .6);
    }
  }

  &.disabled {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.6);
    cursor: not-allowed;
  }

  &:not(&.disabled):hover {
    background-color: #f5f5f5;
  }
}