.container {
  position: fixed;
  left: 0;
  top: 8px;
  z-index: 1001;
  font-size: 14px;
  width: 100%;
  pointer-events: none;
}

.message-notice {
  padding: 8px;
  text-align: center;
  pointer-events: none;

  &.move-appear {
    animation: showMove .1s ease-out;
  }

  &.move-leave {
    animation: hideMove .25s ease-out;
  }
}

.message-notice-content {
  display: inline-flex;
  align-items: center;
  padding: 12px 30px;
  background: #fff;
  border-radius: 1px;
  pointer-events: all;

  .icon {
    margin-right: 12px;
    font-size: 26px;
  }

  &.success {
    background-color: #FFFBE6;
    border: 1px solid #FAAD15;

    .icon {
      color: #FAAD15;
    }
  }

  &.error {
    background-color: #FFF2F0;
    border: 1px solid #F70C0C;

    .icon {
      color: #F70C0C;
    }
  }

  &.warn {
    background-color: #E6F7FF;
    border: 1px solid #1890FF;

    .icon {
      color: #1890FF;
    }
  }

  .content {
    text-align: left;
  }
}

@keyframes showMove {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hideMove {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}